import { ref, watch, computed } from '@vue/composition-api'
import { title } from '@core/utils/filter'
import Vue from 'vue'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import i18n from '@/libs/i18n'

import store from '@/store'

export default function useRolesList() {
  // Use toast
  const toast = useToast()

  const refRoleListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'name',
      label: i18n.t('roles.role'),
      formatter: title,
      sortable: true,
    },
    { key: 'type', sortable: true, label: i18n.t('roles.type') },
    { key: 'area', sortable: true, label: i18n.t('roles.area') },
    { key: 'actions', label: i18n.t('common.actions') },
  ]

  const permColumns = [
    {
      key: 'module',
      label: 'Module',
      formatter: title,
      sortable: false,
    },
    { key: 'show', sortable: false },
    { key: 'list', sortable: false },
    { key: 'view', sortable: false },
    // { key: 'read', sortable: false },
    { key: 'create', sortable: false },
    { key: 'edit', sortable: false },
    { key: 'delete', sortable: false },
  ]

  const perPage = ref(10)
  const totalRoles = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 50, 250, 500]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refRoleListTable.value ? refRoleListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRoles.value,
    }
  })

  const refetchData = () => {
    refRoleListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  })

  const fetchRoles = (ctx, callback) => {
    // console.log('!!!!!!!!!!!!!')
    store
      .dispatch('app-role/fetchRoles', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        plan: planFilter.value,
        status: statusFilter.value,
      })
      .then(response => {
        // console.log('!!!!!!!!!!!!!')
        // console.log(response)
        const { roles, total } = response.data

        callback(roles)
        totalRoles.value = total
      })
      // .catch(err => {
      .catch(() => {
        // console.log('!!!!!!!!!!!!!')
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching roles list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        // console.log(err)
      })
  }

  const deleteRole = item => {
    // console.log(Vue.swal)
    // console.log(store._vm)
    Vue.swal({
      title: 'Are you sure?',
      html: `Role <b>${item.name}</b> will be deleted. \nYou won't be able to revert this!`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ml-1',
      },
      buttonsStyling: false,
    }).then(result => {
      if (result.value) {
        store
          .dispatch('app-role/deleteRole', { id: item.id })
          .then(() => {
            refetchData()
            Vue.swal({
              icon: 'success',
              title: 'Deleted!',
              text: `Role ${item.fullName} has been deleted.`,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Role delete error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveRoleRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveRoleRoleIcon = role => {
    if (role === 'subscriber') return 'RoleIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'RoleIcon'
  }

  const resolveRoleStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchRoles,
    tableColumns,
    permColumns,
    perPage,
    currentPage,
    totalRoles,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refRoleListTable,

    resolveRoleRoleVariant,
    resolveRoleRoleIcon,
    resolveRoleStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,

    deleteRole,
  }
}
